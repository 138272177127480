!function (window, document) {
    'use strict';

    /** @typedef {{id: string, cmpName: string, isIab: boolean, adStack: boolean}} Vendor */
    /** @typedef {{
     *   gdprApplies: boolean,
     *   tcString: string,
     *   eventStatus: string,
     *   vendor: {consents: boolean[], legitimateInterests: boolean[]},
     *   purpose: {consents: boolean[], legitimateInterests: boolean[]}
     * }} TcData */
    /** @typedef {{
     *   ads: {stroeer: metaTagUrl: string},
     *   cmp: {baseUrl: string, siteId: string, privacyManagerId: number},
     *   pur: {baseUrl: string, pageId: string, privacyManagerId: number, useContentpass: boolean}
     * }} AdsConfig */
    /** @typedef {{
     *   adsConfig?: AdsConfig,
     *   cmpPrivacyManagerId: number,
     *   cmpBaseUrl: string
     * }} SsoConfig */
    /**
     * @callback VendorStructResolveFunction
     * @function
     * @param {boolean} allowed
     */

    /**
     * @type ?string Placeholder for the init code to set a layer test variant. As the log event is not bound
     * this is the easiest place to handle this
     */
    let layerTestVariant = null;

    /**
     * @param params {{category: string, action: string}}
     * @param where {'ga'|'proxy'}
     */
    const logEvent = (params, where = 'ga') => {
        setTimeout(() => {
            if (where === 'proxy') {
                const payload = {
                    'hitType': 'event',
                    'eventCategory': params.category,
                    'eventAction': params.action,
                    'nonInteraction': true,
                };
                if (params.category === 'cmp' && layerTestVariant) {
                    payload.eventLabel = `variant_${layerTestVariant}`;
                }
                window.sso_analytics.proxyEvent(payload);
            }

            if (where === 'ga' && window.ga) {
                const payload = {
                    'hitType': 'event',
                    'eventCategory': params.category,
                    'eventAction': params.action,
                    'nonInteraction': true,
                }
                ga('send', payload);
            }
        })
    }

    const noop = () => {};

    /** @type {{[vendor: string]: Vendor}} */
    const VendorList = {
        SDI: {id: '1057', cmpName: 'Ströer Digital Media GmbH', isIab: true, adStack: true},
        AmazonAds: {id: '793', cmpName: 'Amazon Europe Core S.à r.l.', isIab: true, adStack: true},
        GAM: {id: '755', cmpName: 'Google Advertising Products', isIab: true, adStack: true},
        Yieldlove: {id: '251', cmpName: 'Yieldlove GmbH', isIab: true, adStack: true},
        SeedingAlliance: {id: '371', cmpName: 'Seeding Alliance GmbH', isIab: true, adStack: true},
        DefineMedia: {id: '440', cmpName: 'DEFINE MEDIA GMBH', isIab: true, adStack: true},
        Taboola: {id: '42', cmpName: 'Taboola Europe Limited', isIab: true, adStack: true},
        Seedtag: {id: '157', cmpName: 'Seedtag Advertising S.L', isIab: true, adStack: true}, // Recognified
        OnlineSolution: {id: '602', cmpName: 'Online Solution', isIab: true, adStack: false}, // Recognified
        AdDefend: {id: '539', cmpName: 'AdDefend GmbH', isIab: true, adStack: true}, // Tisoomi
        INFOnline: {id: '730', cmpName: 'INFOnline GmbH', isIab: true, adStack: false},
        OptiDigital: {id: '915', cmpName: 'Opti Digital SAS', isIab: true, adStack: false},
        Opinary: {id: '488', cmpName: 'Opinary GmbH', isIab: true, adStack: false},

        GA: {id: '5e542b3a4cd8884eb41b5a72', cmpName: 'Google Analytics', isIab: false, adStack: false},
        Speedcurve: {id: '5f342c06496ee40b5b984f93', cmpName: 'Speedcurve', isIab: false, adStack: false},
        Facebook: {id: '5e716fc09a0b5040d575080f', cmpName: 'Facebook, Inc.', isIab: false, adStack: false},
        ConversionCowboys: {id: '6552412ad57bad06a83f87fb', cmpName: 'Conversion Cowboys GmbH', isIab: false, adStack: false},
        VgWort: {id: '5ef513df0b45880aa11f804d', cmpName: 'VG Wort', isIab: false, adStack: false},
        Utiq: {id: '655c7f831fa5570547d3459f', cmpName: 'Utiq SA/NV', isIab: false, adStack: false},
    };

    // noinspection JSUnusedGlobalSymbols
    const DefaultConfig = {
        accountId: 375,
        // baseEndpoint: sso.init.cmpBaseUrl,
        targetingParams: {},
        gdpr: {
            shortCircuitPartialConsent: true,
        },
        events: {
            onConsentReady: () => {
                console.log('CONSENT READY');
            },
            onMessageReady: () => {
                // This event fires when a message is about to display.
                logEvent({category: 'cmp', action: 'open-consent-message'}, 'proxy');
            },
            onMessageChoiceSelect : (_messageType, _choiceID, choiceTypeID) => {
                switch(choiceTypeID) {
                    // The user has chosen an action with custom JavaScript
                    case 9:
                        logEvent({category: 'cmp', action: 'js-action'}, 'proxy');
                        break;
                    // The user has chosen the "Accept All" option in a consent message
                    case 11:
                        logEvent({category: 'cmp', action: 'accept-all'}, 'proxy');
                        break;
                    // The user has chosen to view a privacy manager (consent preferences) UI.
                    case 12:
                        logEvent({category: 'cmp', action: 'select-privacy-manager'}, 'proxy');
                        break;
                    // The user has chosen the "Reject All" message from a consent message
                    case 13:
                        logEvent({category: 'cmp', action: 'reject-all'}, 'proxy');
                        break;
                }
            },
            onPrivacyManagerAction: (_messageType, pmData) => {
                // noinspection JSUnresolvedVariable
                if (pmData?.purposeConsent === 'none' && pmData?.vendorConsent === 'none') {
                    // PUR mode has no reject. So reject means sbd clicked on revoke, so reload and show layer
                    window.sso_analytics.proxyEvent({'hitType': 'event', 'eventCategory': 'cmp', 'eventAction': 'pm-revoke', 'nonInteraction': true});
                    console.log('%cREVOKE: step 1', 'background: #e83e8c; color: #fff');
                    // Problem: this event fires before the SP state is persistent. Reloading too soon looses the revoke.
                    // Solution: Explicitly revoking via code as this function incorporates a callback.
                    // Note: This essentially does revoke the consent twice; but second time we know it actually happened
                    window.sso.consent.revokeConsent(() => {
                        console.log('%cREVOKE: step 2', 'background: #e83e8c; color: #fff');
                        location.reload();
                    });
                }
            },
            onSPPMObjectReady: () => {},
        },
    }
    //NOTE: set a base config; this does not need to wait for anything and is always valid
    window._sp_queue = [];
    window._sp_ = {config: DefaultConfig};

    class ContentPass {
        /** @param adsConfig {AdsConfig} */
        constructor(adsConfig) {
            cp('create', adsConfig?.pur?.pageId ?? '', {
                baseUrl: adsConfig?.pur?.baseUrl ?? '',
            });
        }

        signup() {
            logEvent({category: 'pur-layer', action: 'signup'}, 'proxy');
            cp('signup');
        }
        login() {
            logEvent({category: 'pur-layer', action: 'login'}, 'proxy');
            cp('login');
        }
        logout() {
            logEvent({category: 'pur-layer', action: 'logout'}, 'proxy');
            cp('logout');
            console.log('%cPUR: logout', 'background: #ff0000; color: white;');
        }

        checkBypass() {
            return new Promise((resolve, _reject) => {
                try {
                    if (Cookies.get('_cpauthhint') === '1') return;
                    performance.mark('sso-cp-short');
                    resolve(Consent.modes.SDI);
                } catch (e) {
                    // Ignore all errors. This is just the shortcut!
                    console.log('%cCP shortcut error', 'background: #ff0000; color: white;', e);
                }
            });
        }

        authenticate() {
            // logEvent({category: 'pur-traffic', action: 'pur-auth'});  Disabled until next PUR launch
            return new Promise((resolve, reject) => {
                cp('authenticate', (error, user) => {
                    if (error) {
                        console.warn('%cCP error: ', 'background: #f44336; color: white;', error);
                        logEvent({category: 'pur-traffic', action: 'pur-error'}, 'proxy');
                        reject();
                    }
                    // noinspection JSUnresolvedFunction
                    if (user.isLoggedIn() === true) {
                        console.log('%cPUR: ', 'background: #0396ff; color: white;', 'user is logged in');
                    }
                    // noinspection JSUnresolvedFunction
                    if (user.hasValidSubscription() === true) {
                        console.log('%cPUR: ', 'background: #0396ff; color: white;', 'valid subscription');
                        logEvent({category: 'pur-traffic', action: 'pur-sub'}, 'proxy');
                        resolve(Consent.modes.PUR);
                    } else {
                        console.log('%cPUR: ', 'background: #0396ff; color: white;', 'no valid subscription — going for metatag');
                        // logEvent({category: 'pur-traffic', action: 'pur-sdi'});  Disabled until next PUR launch
                        resolve(Consent.modes.SDI);
                    }
                });
            });
        }
    }

    let setFinalConsentMode = noop;

    class Consent {
        static modes = Object.freeze({PUR: 'pur', SDI: 'sdi', LEGACY: 'legacy'});
        /** @type {'pur' | 'sdi' | 'legacy'} */
        mode = 'legacy';
        finalMode = new Promise((resolve, _reject) => setFinalConsentMode = (mode) => {
            setFinalConsentMode = noop;
            this.mode = mode;
            resolve(mode);
        });
        vendorList = VendorList;
        /** @type {ContentPass} */
        contentPass;
        _spConfig = DefaultConfig;
        /** @type {SsoConfig} */
        _ssoConfig;
        /** @type {AdsConfig} */
        _adsConfig;
        /** @type {Map<string, {vendor: Vendor, blocking: Promise, nonBlocking: Promise, resolve: Function, reject: Function}>} */
        _consents = new Map();
        _iabData = {tcString: '', gdprApplies: true};

        get modes() {
            return Consent.modes;
        }

        get tcString() {
            return this._iabData.tcString;
        }

        get gdprApplies() {
            return this._iabData.gdprApplies;
        }

        get privacyManagerId() {
            return this.mode === Consent.modes.PUR
                ? this._adsConfig.pur.privacyManagerId
                : (this.mode === Consent.modes.SDI
                    ? this._adsConfig.cmp.privacyManagerId
                    : this._ssoConfig.cmpPrivacyManagerId);
        }

        /** @param ssoConfig {SsoConfig} */
        constructor(ssoConfig) {
            this._ssoConfig = ssoConfig;
            this._adsConfig = ssoConfig?.adsConfig;
            if (this._adsConfig?.pur?.useContentpass) {
                this.contentPass = new ContentPass(this._adsConfig);
            }
            Object.values(this.vendorList).forEach((vendor) => {
                const callbacks = {};
                const struct = {
                    vendor,
                    blocking: new Promise((resolve, reject) => callbacks.blocking = {resolve, reject}),
                    nonBlocking: new Promise((resolve, reject) => callbacks.nonblocking = {resolve, reject}),
                    resolve: () => {
                        callbacks.nonblocking.resolve();
                        callbacks.blocking.resolve();
                    },
                    reject: () => callbacks.nonblocking.reject(),
                };
                struct.nonBlocking.catch(() => {});  // Ensures reject does not cause error if nobody else uses it
                this._consents.set(vendor.id, struct);
            });
        }

        /**
         * @param vendor {Vendor}
         * @param allowedCallback {Function}
         * @param rejectedCallback {Function?}
         */
        dependsOn(vendor, allowedCallback, rejectedCallback) {
            if (!this._consents.has(vendor?.id)) return;
            const vendorStruct = this._consents.get(vendor.id);
            const promise = !!rejectedCallback ? vendorStruct.nonBlocking : vendorStruct.blocking;
            promise.then(allowedCallback, rejectedCallback).catch(() => {});
        }

        /**
         * @param vendors {Array.<Vendor>}
         * @param allowedCallback {Function}
         * @param rejectedCallback {Function?}
         */
        dependsOnMultiple(vendors, allowedCallback, rejectedCallback) {
            if (vendors.some((vendor) => !this._consents.has(vendor?.id))) return;
            const vendorStructs = vendors.map((vendor) => this._consents.get(vendor.id));
            const promises = vendorStructs.map((s) => !!rejectedCallback ? s.nonBlocking : s.blocking);
            Promise.all(promises).then(allowedCallback, rejectedCallback).catch(() => {});
        }

        /** @param params {{preventLayer?: boolean, useStaging?: boolean, layerVariant?: string}?} */
        init(params) {
            if (params?.preventLayer) {
                this._spConfig.targetingParams.exclude = 'noLayer';
            }
            if (params?.useStaging) {
                this._spConfig.campaignEnv = 'stage';
            }
            if (params?.layerVariant) {
                this._spConfig.targetingParams.variant = params.layerVariant;
                layerTestVariant = params.layerVariant;
            }
            if (this._adsConfig?.cmp?.siteId) {
                this._spConfig.propertyId = +this._adsConfig.cmp.siteId;
            }
            if (this._adsConfig?.pur?.useContentpass) {
                this._spConfig.isSPA = true;
                this._spConfig.baseEndpoint = this._adsConfig.cmp.baseUrl;

                this.finalMode
                    .then((mode) => {
                        // Needed here as SDI mode always requires a Reject-PM (even if subsequent PUR mode)!
                        if (mode === Consent.modes.PUR) {
                            // No layer in PUR mode
                            this._spConfig.targetingParams.exclude = 'noLayer';
                        }
                        return mode;
                    })
                    .then(() => window.addEventListener('pageshow', this._pageShowEventHandler))
                    .then(() => this._triggerLayer())
                    .catch(() => console.log('%cCMP: CP authentication failed', 'background: #b90000; color: #fff'));
            } else {
                this._spConfig.baseEndpoint = this._adsConfig?.cmp?.baseUrl ?? this._ssoConfig.cmpBaseUrl;
            }

            this._listenForTcfConsentUpdate()
                .then((tcData) => this._processIabData(tcData))
                .then(() => this._fetchCustomVendorInfo())
                .then((customVendorData) => this._processCustomVendorData(customVendorData))
                .then(() => window.removeEventListener('pageshow', this._pageShowEventHandler))
                // .then(() => this._logAdState())  Disabled until next PUR launch
                .catch(() => console.log('%cCMP: TCF processing failed', 'background: #b90000; color: #fff'));
        }

        run() {
            if (this._adsConfig?.pur?.useContentpass) {
                const cpAuthenticated = this.contentPass.authenticate();
                const cpShortcut = this.contentPass.checkBypass();
                cpAuthenticated.then((mode) => {
                    try {
                        performance.mark('sso-cp-done');
                    } catch (e) {}
                    if (mode === Consent.modes.PUR) {
                        // Reject all consent promises
                        this._consents.forEach((struct) => struct.reject());
                        this._displaySubscriptionManagementLinksInNavigation();
                    }
                    return mode;
                });

                Promise.race([cpShortcut, cpAuthenticated]).then((mode) => setFinalConsentMode(mode));
            } else {
                setFinalConsentMode(this.modes.SDI);
            }
        }

        /** @param tab {'vendors' | 'purposes' | 'features'?} */
        loadPrivacyManager(tab) {
            logEvent({category: 'cmp', action: 'pm-manual-open'}, 'proxy');
            // TODO: cleanup after multi campaign launched
            if (window._sp_.hasOwnProperty('gdpr')) {
                window._sp_.gdpr.loadPrivacyManagerModal(this.privacyManagerId, tab);
            } else {
                window._sp_.loadPrivacyManagerModal(this.privacyManagerId, tab);
            }
        }

        revokeConsent(callback) {
            sso.embeds.deleteCookie();
            __tcfapi('postRejectAll', 2, callback);
        }

        _triggerLayer() {
            try {
                performance.mark('sso-triggerlayer');
            } catch (e) {
            }
            // noinspection JSUnresolvedFunction
            _sp_.executeMessaging();
        }

        _pageShowEventHandler(event) {
            if (event.persisted) {
                // Cycling back/forward could lead to multiple open layer
                if (document.querySelector('html.sp-message-open')) {
                    _sp_.destroyMessages();
                }
                // Appears to only work outside the immediate event context
                setTimeout(() => this._triggerLayer());
            }
        }

        _listenForTcfConsentUpdate() {
            return new Promise((resolve, reject) => {
                // noinspection JSUnresolvedFunction
                __tcfapi('addEventListener', 2, (tcData, success) => {
                    console.log('%cCMP tcf results: ', 'background: #b90000; color: #fff', tcData, success);
                    if (!success) {
                        reject();
                    } else if ('tcloaded' === tcData.eventStatus || 'useractioncomplete' === tcData.eventStatus) {
                        try {
                            if ('tcloaded' === tcData.eventStatus) performance.mark('sso-sp-done-loaded');
                            if ('useractioncomplete' === tcData.eventStatus) performance.mark('sso-sp-done-user');
                            performance.mark('sso-sp-done');
                        } catch (e) {}
                        resolve(tcData);
                    }
                });
            });
        }

        /** @param tcData {{vendor?: {consents: boolean[]}, tcString?: string, gdprApplies?: boolean}} */
        _processIabData(tcData) {
            this._iabData = {
                tcString: tcData?.tcString,
                gdprApplies: tcData?.gdprApplies,
            };
            const iabConsent = tcData?.vendor?.consents ?? {};
            this._consents.forEach((struct, key) => {
                if (!struct.vendor.isIab) return;
                //TODO: this would be the place to add a check for legitimate interest for backwards compatibility
                if (iabConsent.hasOwnProperty(key) && iabConsent[key] === true) {
                    struct.resolve();
                } else {
                    struct.reject();
                }
            });
        }

        _fetchCustomVendorInfo() {
            return new Promise((resolve, reject) => {
                // noinspection JSUnresolvedFunction
                __tcfapi('getCustomVendorConsents', 2, (customConsent, success) => {
                    if (success) {
                        resolve(customConsent);
                    } else {
                        reject();
                    }
                });
            })
        }

        /** @param customVendorData {{grants?: {vendorGrant: boolean}}} */
        _processCustomVendorData(customVendorData) {
            const vendorGrants = customVendorData?.grants ?? {};
            this._consents.forEach((struct, key) => {
                if (struct.vendor.isIab) return;
                if (vendorGrants.hasOwnProperty(key) && vendorGrants[key].vendorGrant === true) {
                    struct.resolve();
                } else {
                    struct.reject();
                }
            });
        }

        _displaySubscriptionManagementLinksInNavigation() {
            document
                .querySelectorAll('.pur-mgmt')
                .forEach((node) => node.classList.remove('d-none'));
        }

        _logAdState() {
            const vendors = Object.values(this.vendorList).filter((vendor) => vendor.adStack);
            const promises = vendors.map((vendor) => this._consents.get(vendor.id).nonBlocking
                .then(() => Promise.resolve('+' + vendor.id))
                .catch(() => Promise.resolve('-' + vendor.id))
            );
            Promise.all(promises).then((states) => {
                logEvent({category: 'adstack', action: 'consent' + states.join('_')});
            });
        }
    }

    window.sso.consent = new Consent(window.sso.init);
}(window, document);
