!function (window, document) {
    'use strict';

    /** @typedef {{
     *   eventCategory: string,
     *   eventAction: string,
     *   eventLabel?: string,
     *   eventValue?: string,
     *   nonInteraction?: boolean,
     * }} EventPayload
     */

    class SSOAnalytics {
        static beaconAvailable = !!navigator.sendBeacon;
        static mapping = {
            campaignSource: 'utm_source',
            campaignMedium: 'utm_medium',
            campaignName: 'utm_name',
            campaignKeyword: 'utm_term',
            campaignContent: 'utm_content',
            dataSource: 'data_source',
            page: 'url',
            title: 'title',
            eventCategory: 'event_category',
            eventAction: 'event_action',
            eventLabel: 'event_label',
            eventValue: 'event_value',
            nonInteraction: 'non_interaction',
            ...Object.fromEntries(Array(19).fill('').map((_v, i) => `dimension${i+1}`).map((key) => [key, key])),
        }
        /** @type {string} */
        _endpointUrl;
        /** @type {Consent} */
        _consent;
        _payload = {dimension20: 'sso_proxy'};
        /** @type {Promise} */
        _initialized;

        /**
         * @param {Consent} consent
         * @param {boolean} isMobile
         * @param {string} trackingId
         * @param {string} endpointUrl
         */
        constructor(trackingId, isMobile, endpointUrl, consent) {
            this._payload.client_id = this._createClientId();
            this._payload.google_tid = trackingId;
            this._payload.data_source = 'web';
            this._payload.device = isMobile ? 'mobile' : 'desktop';
            this._endpointUrl = endpointUrl;
            this._consent = consent;

            let gaFound;
            this._initialized = new Promise((resolve, _) => gaFound = resolve);
            this._initialized
                .then(() => this._consent.dependsOn(
                        this._consent.vendorList.GA,
                        () => {},
                        () => this._trackPageView(),
                    ))
                .catch(() => console.info('%cTRACE:', 'background: #00bcd4; color: white;', 'GA init failed'));
            this._waitForGaInit(gaFound);
        }

        /** @param {EventPayload} payload */
        trackEvent(payload) {
            this._consent.dependsOn(
                this._consent.vendorList.GA,
                () => ga('send', {...payload, hitType: 'event'}),
                () => this.proxyEvent(payload),
            )
        }

        /**
         * @param {EventPayload} eventPayload
         */
        proxyEvent(eventPayload) {
            this._initialized.then(() => {
                const mapping = this.constructor.mapping;
                const payload = {'hit_type': 'event', ...this._payload};
                Object.entries(eventPayload).forEach(([k, v]) => {
                    if (mapping.hasOwnProperty(k)) {
                        payload[mapping[k]] = v;
                    }
                });
                this._sendViaProxy(payload);
            }).catch(() => {});
        }

        _waitForGaInit(resolve, timeout = undefined) {
            if (window.ga && window.ga.q.length) {
                this._parseGaSetup();
                resolve();
            } else {
                setTimeout(() => this._waitForGaInit(resolve, timeout === undefined ? 0 : 250));
            }
        }

        _parseGaSetup() {
            if (!window.ga) return;
            try {
                const mapping = this.constructor.mapping;
                ga.q.forEach((value) => {
                    if (value[0] === 'create') {
                        this._payload.google_tid = value[1];
                    } else if (value[0] === 'set') {
                        if (mapping.hasOwnProperty(value[1])) {
                            this._payload[mapping[value[1]]] = value[2];
                        }
                    }
                });
                if (window.location && !this._payload.hasOwnProperty('url')) {
                    const url = new URL(window.location.href)
                    this._transferUtmParameters(url);
                    this._payload.url = this._sanitizeUrl(url);
                }
            } catch (e) {
                console.error('%cFALLBACK GA ERROR:', 'background: #0396ff; color: white;', e);
            }
        }

        /**
         * Transfer UTM parameters to payload structure to preserve before potentially removing them from URL
         * @param {URL} url
         * @private
         */
        _transferUtmParameters(url) {
            const params = url.searchParams;
            ['utm_source', 'utm_medium', 'utm_name', 'utm_term', 'utm_content']
                .filter((key) => params.has(key))
                .forEach((key) => this._payload[key] = params.get(key));
        }

        /**
         * Removes all information but domain + path
         * @param {URL} url
         * @return {String}
         * @private
         */
        _sanitizeUrl(url) {
            url.hash = '';
            url.search = '';
            url.username = '';
            url.password = '';
            return url.toString();
        }

        _trackPageView() {
            const payload = {'hit_type': 'pageview', ...this._payload};
            this._sendViaProxy(payload);
        }

        _sendViaProxy(payload) {
            fetch(this._endpointUrl, {
                method: 'POST',
                body: JSON.stringify(payload),
                credentials: 'omit',
                keepalive: true,
                mode: 'no-cors',
                cache: 'no-store',
                referrerPolicy: 'no-referrer',
            }).catch(() => {});
        }

        _createClientId() {
            try {
                if (window.crypto) {
                    // noinspection JSUnresolvedVariable
                    if (crypto.randomUUID) {
                        return crypto.randomUUID();
                    }
                    const charPool = [...crypto.getRandomValues(new Uint32Array(5))]
                        .map((m) => m.toString(16).padStart(8, '0'))
                        .join('');
                    const mapping = [['',0,8], ['',8,12], ['4',13,16], ['a',17,20], ['',20,32]];
                    return mapping.map(([lead, start, end]) => lead + charPool.substring(start, end)).join('-');
                }
            } catch (e) {
                // Intentionally left blank
            }
            return null;
        }
    }

    window.sso_analytics = new SSOAnalytics(window.sso.init.analyticsId, window.sso.init.deviceIsMobile, window.sso.urls.pageHit, window.sso.consent);
}(window, document);
